import { trackGTMEvent } from 'packages/google-tag-manager';

/**
 * Tracks an Event in GA
 *
 * @param category - GA Category
 * @param action   - GA Action
 * @param label    - GA Label
 * @param value    - GA optional value
 */
export const trackEvent = (
  category: string,
  action: string,
  label: string,
  value?: any
) => {
  const eventObject = {
    event: 'nsEvent',
    nsEvent_category: category,
    nsEvent_action: action,
    nsEvent_label: label,
    // Sending no value will break event tracking...default to 0 to make GTM happy.
    nsEvent_value: value || 0
  };

  trackGTMEvent(eventObject);
};

/**
 * Tracks custom Event in GA
 *
 * @param event - GA Event name
 * @param category - GA Category
 * @param action   - GA Action
 * @param label    - GA Label
 * @param value    - GA optional value
 */
export const trackCustomEvent = (
  event: string,
  category: string,
  action: string,
  label: string,
  value?: any
) => {
  const eventObject = {
    event,
    nsEvent_category: category,
    nsEvent_action: action,
    nsEvent_label: label,
    // Sending no value will break event tracking...default to 0 to make GTM happy.
    nsEvent_value: value || 0
  };

  trackGTMEvent(eventObject);
};

export const sendReferralCode = (referralCode: string): void => {
  trackGTMEvent({ nsReferralCode: referralCode });
};

/**
 * Tracks a PageView in GA
 */
export const trackPageView = (title: string, page: string) => {
  trackGTMEvent({
    event: 'nsPageView',
    nsPageView_title: title,
    nsPageView_page: page
  });
};

/**
 * Set a custom dimension in GA
 */
export const setCustomDimension = (key: string, value: string | number) => {
  trackGTMEvent({
    [key]: value
  });
};

/**
 * Dispatches the application startup with needed
 * data layer values
 *
 * @param data - Startup Data
 */
export const trackApplicationStartup = (data: {
  mode: string;
  brand?: string;
  variant: string;
}) => {
  const eventData = {
    nsApp: 'Account Center',
    nsMode: data.mode.toLowerCase(),
    nsVariant: data.variant.toLowerCase(),
    ...(data?.brand && { nsBrand: data?.brand?.toLowerCase() })
  };
  trackGTMEvent(eventData);
};
