import { AnalyticsEvent } from 'packages/react-analytics';
import { AcpConfig } from 'apps/acp/packages/acp-config';
import {
  setCustomDimension,
  trackApplicationStartup,
  trackPageView,
  trackEvent,
  trackCustomEvent,
  sendReferralCode
} from './google-tag-manager';
import { installGoogleTagManager } from 'packages/google-tag-manager';

const CONTEXT_PARAMETER = 'ga:context';

export interface GoogleTagManagerConfig {
  brand?: string;
  mode: AcpConfig['mode'];
  variant: AcpConfig['variant'];
  gtmContainerId: string;
}

export const createGoogleTagManagerSendAnalytics = (
  config: GoogleTagManagerConfig
) => {
  // Install Tag Manager
  installGoogleTagManager(config.gtmContainerId);
  trackApplicationStartup(config);

  const sendAnalytics = ({ event, payload }: AnalyticsEvent) => {
    if (event === CONTEXT_PARAMETER) {
      setCustomDimension(payload.key, payload.value);
    } else if (event === 'nsPageView') {
      trackPageView(payload.nsPageView_title, payload.nsPageView_page);
    } else if (event === 'afCustom') {
      trackCustomEvent(
        payload.event,
        payload.category,
        payload.action,
        payload.label,
        payload.value
      );
    } else if (event === 'afRefferalCode') {
      sendReferralCode(payload.referralCode);
    } else {
      trackEvent(payload.category, event, payload.label, payload.value);
    }
  };
  return sendAnalytics;
};
